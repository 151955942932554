import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './css/7eb43c6f102a273f26326585917b1bf1-layout-bundle.css';
import './css/40953-layout.css';
import './css/all.css';
import './css/animate.min.css';
import './css/base.min.css';
import './css/frontend.css';
import './css/jquery.magnificpopup.min.css';
import './css/pgc_sgb_lightbox.min.style.css';
import './css/pgc_sgb.min.style.css';
import './css/skin-6672132d67794.css';
import './css/smartslider.min.css';
import './css/style.min.css';
import './css/style2.css';
import './css/swiper.min.css';
import './css/v4-shims.min.css';
import './css/wc-blocks.css';
import './css/woocommerce-layout.css';
import './css/woocommerce-smallscreen.css';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
