function App() {
  return (
    <>
	<div id="centerer"><div id="center">
    <h1 className="h1cik"> Sorry! We're Under Maintenance</h1>
    <p className="pcik">Hidayet Media is currently under maintenance.</p>
    <p className="pcik">Thanks for your patience.</p></div></div>

    <footer class="fl-builder-content fl-builder-content-30 fl-builder-global-templates-locked" data-post-id="30" data-type="footer" itemscope="itemscope" itemtype="http://schema.org/WPFooter"><div class="fl-row fl-row-full-width fl-row-bg-color fl-node-5a8987bfc20b2 fl-row-default-height fl-row-align-center footer-row1" data-node="5a8987bfc20b2">
	<div class="fl-row-content-wrap">
		<div class="uabb-row-separator uabb-top-row-separator">
</div>
						<div  class="fl-row-content fl-row-fixed-width fl-node-content">
		
<div class="fl-col-group fl-node-5a8987bfc1e8c fl-col-group-custom-width" data-node="5a8987bfc1e8c">
			<div class="fl-col fl-node-5a8987bfc1ec3 fl-col-small fl-col-small-custom-width footer-about" data-node="5a8987bfc1ec3">
	<div class="fl-col-content fl-node-content"><div class="fl-module fl-module-pp-image fl-node-ycj0ar71qps9" data-node="ycj0ar71qps9">
	<div class="fl-module-content fl-node-content">
		{/*Buraya */}
    <div class="pp-heading-content">
		<div class="pp-heading  pp-left">
		
		<h3 class="heading-title">

			
			<span class="title-text pp-primary-title">Hidayet Media Publishing Advertising Services and Foreign Trade</span>

			
			
		</h3>

		
	</div>

	
	</div>
	</div>
</div>
<div class="fl-module fl-module-rich-text fl-node-w7cykq9hur1m" data-node="w7cykq9hur1m">
	<div class="fl-module-content fl-node-content">
		<div class="fl-rich-text">
	<h6><strong>Address:<br/>
</strong><span style={{'color':'#cacbcb'}}>Mimarsinan District Üsküdar Street Yedpa Trade Center No: 1 - B 133 Ataşehir / İSTANBUL</span></h6>
<h6><strong>Phone:<br/>
</strong><a href="tel:+902167002111"><span style={{'color':'#cacbcb'}}>+(90) 216 700 21 11</span></a></h6>
<h6><strong>Email:<br/>
</strong><a href="tel:+902167002111"><span style={{'color':'#cacbcb'}}>contact@hidayetmedya.com.tr</span></a></h6>
</div>
	</div>
</div>
</div>
</div>
			<div class="fl-col fl-node-5a8987bfc1efa fl-col-small fl-col-small-custom-width footer-link" data-node="5a8987bfc1efa">
	<div class="fl-col-content fl-node-content"><div class="fl-module fl-module-pp-heading fl-node-2ir97ojaw8h4 fl-visible-desktop fl-visible-large" data-node="2ir97ojaw8h4">
	<div class="fl-module-content fl-node-content">
		<div class="pp-heading-content">
		<div class="pp-heading  pp-left">
		
		<h6 class="heading-title">

			
			<span class="title-text pp-primary-title">Products</span>

			
			
		</h6>

		
	</div>

	
	</div>
	</div>
</div>
<div class="fl-module fl-module-menu fl-node-m98flg74tpo1" data-node="m98flg74tpo1">
	<div class="fl-module-content fl-node-content">
		<div class="fl-menu fl-menu-responsive-toggle-medium-mobile">
	<button class="fl-menu-mobile-toggle text"><span class="fl-menu-mobile-toggle-label" aria-label="Solutions">Solutions</span></button>	<div class="fl-clear"></div>
	<nav aria-label="Solutions" itemscope="itemscope" itemtype="https://schema.org/SiteNavigationElement"><ul id="menu-footer-links-3" class="menu fl-menu-vertical fl-toggle-none" ><li id="menu-item-52785" class="menu-item menu-item-type-post_type menu-item-object-page"><a href="#">
  Live Broadcast Recording Systems</a></li><li id="menu-item-52784" class="menu-item menu-item-type-post_type menu-item-object-page"><a href="#">
  Voice Announcement and In-Studio Voice Announcement Systems</a></li><li><a href="#">Live Broadcasting and Taped Broadcasting Systems</a></li><li><a href="#"> Internet Broadcasting Systems</a></li></ul></nav></div>
	</div>
</div>
<div class="fl-module fl-module-separator fl-node-d2hqylxwrm9i fl-visible-mobile" data-node="d2hqylxwrm9i">
	<div class="fl-module-content fl-node-content">
		<div class="fl-separator"></div>
	</div>
</div>
</div>
</div>
			<div class="fl-col fl-node-tbs1hnm04ojf fl-col-small fl-col-small-custom-width footer-link" data-node="tbs1hnm04ojf">
	<div class="fl-col-content fl-node-content"><div class="fl-module fl-module-pp-heading fl-node-0cykflqeja9z fl-visible-desktop fl-visible-large" data-node="0cykflqeja9z">
	<div class="fl-module-content fl-node-content">
		<div class="pp-heading-content">
		<div class="pp-heading  pp-left">
		
		<h6 class="heading-title">

			
			<span class="title-text pp-primary-title">Solutions</span>

			
			
		</h6>

		
	</div>

	
	</div>
	</div>
</div>
<div class="fl-module fl-module-menu fl-node-10n3jzhr6fwq" data-node="10n3jzhr6fwq">
	<div class="fl-module-content fl-node-content">
		<div class="fl-menu fl-menu-responsive-toggle-medium-mobile">
	<button class="fl-menu-mobile-toggle text"><span class="fl-menu-mobile-toggle-label" aria-label="Products">Products</span></button>	<div class="fl-clear"></div>
	<nav aria-label="Products" itemscope="itemscope" itemtype="https://schema.org/SiteNavigationElement"><ul id="menu-footer-links-2" class="menu fl-menu-vertical fl-toggle-arrows" ><li id="menu-item-52882" class="menu-item menu-item-type-taxonomy menu-item-object-product_cat"><a href="#">Radio/Television Studio Mixers</a></li><li id="menu-item-52884" class="menu-item menu-item-type-taxonomy menu-item-object-product_cat"><a href="#">Signal Boosters</a></li></ul></nav></div>
	</div>
</div>
<div class="fl-module fl-module-separator fl-node-yhldog1i2qvw fl-visible-mobile" data-node="yhldog1i2qvw">
	<div class="fl-module-content fl-node-content">
		<div class="fl-separator"></div>
	</div>
</div>
</div>
</div>


	</div>
		</div>
	</div>
</div>


</footer>
    </>
  );
}

export default App;
